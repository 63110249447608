import React, { useState, useEffect } from "react";

const ArtPage = () =>
{
    return(
        <div>
            <h1 style={{fontSize: "100px"}}>This is art page</h1>
        </div>
    )
}

export default ArtPage