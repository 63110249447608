import React, { useState, useEffect } from "react";
import { Home } from "../components/Home/Home.js";



function HomePage() {

  return (
          <Home/>
  );
}

export default HomePage;
