import React, { useState, useEffect } from "react";
import { Portfolio } from "../components/Portfolio/Portfolio";

const PortfolioPage = () =>
{
    return(
        <>
            <Portfolio/>
        </>
    )
}

export default PortfolioPage